import DocumentTitle from "../DocumentTitle";
import ProjectCard from "./ProjectCard";

export default function Projects() {
    DocumentTitle("Projets - 8bitmd")
    return (
        <div>
            <ProjectCard />
        </div>
    )
}